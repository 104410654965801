.main-container {
  width: 100%;
  height: auto;
  background-color: white;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  padding-top: 10px;
  border-radius: 5px;
}

.form-button {
  margin-top: 20px;
  margin-left: 39%;
}

.table-common {
  height: 550px;
  /* max-height: 550px; */
  width: 100%;
  background-color: #fff;
  padding: 10px;
  padding-bottom: 20px;
  border-radius: 8px;
  text-align: center;
}
.heading1 {
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
}
.heading2 {
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
}
.heading-table {
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: left;
}
.text-center {
  text-align: center;
}
.d-flex-center95 {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.d-flex-center90 {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-container-single {
  width: 100%;
  background-color: #fff;
  height: auto;
  /* padding: 10px 30px 30px 30px; */
  border-radius: 5px;
}
.main-container-single-reciept {
  width: 100%;
  background-color: #fff;
  height: auto;
  padding: 20px 30px 30px 30px;
}

.d-flex-space-bw {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* donation reciept */
.flexContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mr-20 {
  margin-right: 20px;
}

/* pdf download functionality css */
.pdf-grid {
  margin-top: 8px;
  background-color: #fff;
  /* background-color:rgb(250 250 238 / 60%) ; */
  width: 100%;
  margin: auto;
}
.mt-18 {
  margin-top: 18px;
}
.mt-50 {
  margin-top: 50px;
}
.pdf-topbar {
  background-color: #0A2742;
  color: #fff;
  width: 100%;
  padding: 10px 20px 10px;
  display: flex;
  justify-content: space-between;
  line-height: 1.5;
  margin-bottom: 20px;
}
.pdf-top-div {
  display: flex;
  flex-direction: column;
}
/* margin */
.mAuto {
  margin: auto;
}
.mt_2px {
  margin-top: 2px;
}
.mt_20px {
  margin-top: 20px;
}
.mt_35px {
  margin-top: 35px;
}
.mr_20px {
  margin-right: 20px;
}
.mb_40px {
  margin-bottom: 40px;
}
.mb_15px {
  margin-bottom: 15px;
}
/* width */
.w60 {
  width: 60%;
}
.w_80px {
  width: 80px;
}
.w40 {
  width: 40%;
}
.w_150px {
  width: 150px;
}
/* height */
.h-300px {
  height: 300px;
}

.wh-100 {
  width: 100px;
  height: 100px;
}
/* flex css */
.dFlex {
  display: flex;
}
.justifyCenter {
  justify-content: center;
}
.justifyBtw {
  justify-content: space-between;
}
.alignCenter {
  align-items: center;
}
.flexEnd {
  justify-content: flex-end;
}
.volunteer-p {
  width: 100%;
  text-align: center;
  margin: 20px;
}
.flex-col {
  flex-direction: column;
}

/* donor-prompt */
.donor-prompt {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 80vh;
  padding-right: 4rem;
  padding-left: 4rem;
  background-color: #fff;
}
.prompt-btn {
  margin-right: 2rem;
  height: 3rem;
}
.prompt-bar {
  width: 0.15rem;
  height: 50vh;
  background-color: #000;
}
.prompt-div2 {
  display: flex;
  flex-direction: row;
}
.prompt-btn2 {
  height: 3rem;
  margin-left: 2rem;
}
@media (max-width: 790px) {
  .donor-prompt {
    flex-direction: column;
    justify-content: space-evenly;
  }
  .prompt-bar {
    height: 0.15rem;
    width: 50vw;
  }
  .prompt-div2 {
    flex-direction: column;
  }
  .prompt-btn {
    margin-right: 0rem;
  }
  .prompt-btn2 {
    margin-left: 0rem;
    margin-top: 3rem;
  }


}
/* 
dFlex justifyCenter alignCenter
*/
.permission {

  display: flex;
  gap: 8px;
  padding: 5px;
  border-right: 1px solid #e8e3e3;
  border-radius: 5px;
  width: 250px;
  padding-right: 20px;


}
.permission1 {

  display: flex;
  padding: 0px 3px 0px 17px;
  gap: 15px;
  border-bottom: 1px solid #e8e3e3;

}
@media only screen and (max-width: 1039px) {
  .permission {

    display: flex;
    gap: 8px;
    padding: 5px;
    border-right: 1px solid #e8e3e3;
    border-radius: 5px;
    width: 250px;
    padding-right: 20px;
    flex-wrap: wrap;
    justify-content: center;

  }
  .permission1 {
    display: flex;
    padding: 0px 3px 0px 17px;
    gap: 15px;
    border-bottom: 1px solid #e8e3e3;
    flex-wrap: wrap;
    justify-content: center;

  }
}


@media screen and (min-width: 300px) and (max-width: 418px) {
  .desktop-display-none {
    display: inline !important;
  }
  .desktop-display-none-rs {
    display: block !important;
  }
  .icon-flex-dashboard {
    display: flex !important;
    flex-direction: column;
    justify-content: space-around !important;
    width: 100% !important;
  }
  .d_mob {
    font-size: 55px !important;
  }
}

@media screen and (min-width: 419px) and (max-width: 765px) {
  .desktop-display-none {
    display: inline !important;
  }
  .desktop-display-none-rs {
    display: none !important;
  }
  .icon-flex-dashboard {
    display: flex !important;
    justify-content: space-around !important;
    width: 100% !important;
  }
  .dashboard-ngo {
    font-size: 95% !important;
    size: 80 !important;
  }

}

@media screen and (min-width: 100px) and (max-width: 765px) {
  .mob-display-none {
    display: none !important;
  }
}
@media screen and (min-width: 765px) {
  .desktop-display-none {
    display: none !important;
  }
  .desktop-display-none-rs {
    display: none !important;
  }
}

.cash-card-header {
  font-weight: 600 !important;
  font-size: 20px;
}

.cash-pay {

  height: 70px;
  padding: 10px;
  border-radius: 8px;
  color: #fff;
}
.cash-img {
  width: 50%;
  padding: 0px;
  margin-right: 15px;
}

.custom-badge {
  position: absolute;
  top: -10px;
  right: -6px;
  background-color: rgb(253, 10, 10);
  width: 35px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Mobile */
@media screen and (max-width: 765px) {
  .display-prop-desk {
    display: none;
  }
}

/* Desktop */
@media screen and (min-width: 766px) {
  .display-prop-mob {
    display: none !important;
  }
}
.golden-font {
  color: #F2C90A !important;
}